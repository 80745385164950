import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Mietkauf = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Mietkauf – das beste beider Welten?" showCalc={false} />
            <Article>
                <p>
                    Du tr&auml;umst vom Eigenheim, bist dir aber noch nicht sicher, ob der richtige Zeitpunkt f&uuml;r
                    den Kauf schon gekommen ist und m&ouml;chtest lieber noch abwarten? Dann ist ein Mietkauf
                    m&ouml;glicherweise genau das Richtige f&uuml;r dich! Was das ist und worauf du dabei achten musst,
                    erkl&auml;ren wir dir im folgenden Beitrag.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was hei&szlig;t das eigentlich?</h2>
                <p>
                    Ganz grunds&auml;tzlich meint Mietkauf ein Mietverh&auml;ltnis, bei dem du als Mieter:in die Option
                    hast, die betreffende Immobilie nach Ablauf einer bestimmten Frist zu kaufen. Anders als beim
                    herk&ouml;mmlichen Immobilienerwerb musst du also zum Zeitpunkt des Einzugs keinen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    aufnehmen.
                </p>
                <hr />

                <h2>Wie funktioniert ein Mietkauf?</h2>
                <p>
                    Nachdem wir gekl&auml;rt haben, was ein Mietkauf ist, widmen wir uns nun den vier Phasen, in denen
                    er &uuml;blicherweise abl&auml;uft.
                </p>
                <h3>1. Mietvertrag</h3>
                <p>
                    Zun&auml;chst unterzeichnest du einen Mietvertrag, in dem sowohl die Kaufoption als auch der
                    Zeitpunkt fixiert wird, zu dem diese in Kraft tritt. Achtung: Zum Zeitpunkt des Vertragsschlusses
                    ist dir der sp&auml;tere Kaufpreis m&ouml;glicherweise noch nicht bekannt.
                </p>
                <h3>2. Beitrag zur Finanzierung</h3>
                <p>
                    Das Recht auf die Kaufoption erwirbst du, indem du eine Anzahlung an den*die Vermieter*in
                    entrichtest. Solltest du dich nach Ablauf der vertraglich vereinbarten Mietdauer gegen den Kauf der
                    Immobilie entscheiden, so wird ein Teil dieser Summe zur&uuml;ckerstattet.
                </p>
                <h3>3. Miete</h3>
                <p>
                    Nun darfst du die jeweilige Immobilie f&uuml;r die vereinbarte Mietdauer bewohnen. In diesem
                    Zeitraum besitzt du den rechtlichen Status eine:r herk&ouml;mmlichen Mieter:in. In &Ouml;sterreich
                    ist es durch eine 2019 verabschiedete Novelle im Wohngemeinn&uuml;tzigkeitsgesetz m&ouml;glich, die
                    Kaufoption bereits nach 5 Jahren in Kraft treten zu lassen. Nach sp&auml;testens 15 Jahren erlischt
                    das Recht auf den Erwerb der Immobilie in den meisten F&auml;llen. Einige gemeinn&uuml;tzige
                    Genossenschaften und Bauvereinigungen erm&ouml;glichen ihren Mieter:innen den Kauf jedoch auch nach
                    diesem Zeitraum.
                </p>
                <h3>4. Kaufentscheidung</h3>
                <p>
                    Ist die vertraglich fixierte Dauer der Miete abgelaufen, dann hast du drei Optionen. Du kannst
                    entweder
                </p>
                <ul>
                    <li>weiterhin zur Miete wohnen oder</li>
                    <li>den Mietvertrag k&uuml;ndigen oder</li>
                    <li>die Immobilie kaufen</li>
                </ul>
                <p>
                    Solltest du dich f&uuml;r den Kauf entscheiden, so beachte unbedingt, dass die bereits bezahlte
                    Miete in &Ouml;sterreich nicht vom Preis der Immobilie abgezogen wird.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wo finde ich Objekte zum Mietkauf?</h2>
                <p>
                    Suchst nach Objekten zum Mietkauf, so sind dann sind gemeinn&uuml;tzige Genossenschaften und
                    Bauvereinigungen die erste Adresse. Wende dich am besten an die Bauvereinigung deines
                    Wunsch-Wohnortes oder durchsuche einschl&auml;gige Immobilienportale. Doch sei schnell: Die
                    betreffenden Immobilien werden oft noch w&auml;hrend der Bauphase ausgeschrieben und sind hei&szlig;
                    begehrt.
                </p>
                <hr />

                <h2>Welche Vorteile hat der Mietkauf?</h2>
                <p>
                    Da es sich bei Immobilien, die zum Mietkauf angeboten werden, um gef&ouml;rderte Objekte handelt,
                    ist nicht nur ihr Verkaufspreis meist niedriger als der von frei verf&uuml;gbarem Wohnraum. Auch die
                    monatliche Miete f&auml;llt &uuml;blicherweise geringer aus. Zudem hast du die M&ouml;glichkeit, das
                    betreffende Objekt vor dem Kauf zu bewohnen &ndash; so kannst du pr&uuml;fen, ob du dich
                    l&auml;ngerfristig darin wohlf&uuml;hlst. W&auml;hrend dieser Zeit hast du dar&uuml;ber hinaus
                    Gelegenheit, Kapital f&uuml;r den sp&auml;teren Kauf anzusparen. Dieser ist jedoch nicht
                    obligatorisch: Du kannst die Immobilie auch weiterhin mieten oder den Mietvertrag generell
                    aufl&ouml;sen.
                </p>
                <hr />

                <h2>Was sind die Nachteile des Mietkaufs?</h2>
                <p>
                    Da deine monatliche Mieten nicht vom sp&auml;teren Kaufpreis abgezogen wird, ist der Mietkauf keine
                    Finanzierungsform. Der Kaufpreis ist bei der Unterzeichnung des Mietvertrags zudem nicht selten
                    unbekannt, was zu Unsicherheit in deiner Planung f&uuml;hrt. Au&szlig;erdem stellt die oben genannte
                    Anzahlung, die du bei Vertragsabschluss entrichten musst, einen Kostenpunkt dar, der zu den
                    sp&auml;teren{" "}
                    <Link to="/artikel/kaufnebenkosten/" target="_blank" rel="noreferrer noopener">
                        Nebenkosten
                    </Link>{" "}
                    beim Kauf hinzukommt. Entscheidest du dich gegen den Kauf, erh&auml;ltst du die Anzahlung zudem nur
                    teilweise zur&uuml;ck.
                </p>
                <hr />

                <h2>Fazit &ndash; Wann ist ein Mietkauf sinnvoll?</h2>
                <p>
                    Ein Mietkauf eignet sich f&uuml;r dich insbesondere dann, wenn du augenblicklich noch nicht
                    &uuml;ber die erforderlichen Mittel f&uuml;r den Kauf einer Immobilie verf&uuml;gst. Zwar zahlst du
                    neben dem Kaufpreis zumindest 5 Jahre lang Miete, in diesem Zeitraum kannst du dein zuk&uuml;nftiges
                    Eigenheim allerdings ausgiebig testen und w&auml;hrenddessen Eigenkapital f&uuml;r den Erwerb
                    ansparen. Du bist dir noch nicht sicher, ob ein Mietkauf f&uuml;r dich das Richtige ist? Unsere
                    Finazierungsexpert:innen helfen dir gerne! Vereinbare noch heute ein kostenloses Erstgespr&auml;ch!
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Beratungsgespräch vereinbaren!
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"mietkauf"}></BreadcrumbList>
            <ArticleStructuredData page={"mietkauf"} heading={"Mietkauf – das beste beider Welten?"} />
        </Layout>
    );
};

export default Mietkauf;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.mietkauf", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
